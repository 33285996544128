<script>
import brandsLogos from '../brands-logos.js'

export default {
	props: {
		data: Object,
	},
	data() {
		return {
			selectedBrand: null,
			selectedEntity: null,
		}
	},
	created() {
		this.selectedBrand = this.brands[0]
	},
	computed: {
		filterPlans() {
			return this.plansGroups.filter((plan) => plan.brand.code === this.selectedBrand.code)
		},
		plansGroups() {
			return this.data.plansGroups.map((p) => {
				p.brand.image = brandsLogos[p.brand.code]
				return p
			})
		},
		entities() {
			return this.filterPlans.map((d) => d.entity)
		},
		brands() {
			return this.plansGroups.map((d) => d.brand)
		},
		selectedPlan() {
			return this.plansGroups
				.find(
					(p) => p.brand.code === this.selectedBrand.code && p.entity.code === this.selectedEntity.code
				)
				.plans.sort((a, b) => a.qty - b.qty)
		},
	},
	methods: {
		noSurcharge(plan) {
			return plan.surcharge === 0 && plan.qty > 1
		},
	},
	watch: {
		selectedBrand() {
			let entity
			if (this.selectedEntity)
				entity =
					this.filterPlans.find((p) => p.entity.code === this.selectedEntity.code)?.entity ||
					this.filterPlans[0].entity
			else entity = this.filterPlans[0].entity

			this.selectedEntity = entity
		},
	},
}
</script>

<template>
	<div v-if="selectedBrand && selectedEntity">
		<v-container>
			<v-row>
				<v-col cols="12" md="6">
					<PaymentsModal-CreditCard-Select
						v-if="selectedEntity"
						v-model="selectedBrand"
						:items="brands"
						item-text="name"
						item-value="code"
						return-object
						filled
					/>
				</v-col>
				<v-col cols="12" md="6">
					<PaymentsModal-CreditCard-Select
						v-model="selectedEntity"
						:items="entities"
						item-text="displayName"
						item-value="code"
						return-object
						filled
					/>
				</v-col>
			</v-row>
		</v-container>
		<v-simple-table :dense="$b.m" fixed-header class="payments-table">
			<template v-slot:default>
				<thead>
					<tr>
						<th></th>
						<th>Cantidad de Cuotas</th>
						<th>Valor de la cuota</th>
						<th>Precio total financiado</th>
						<th>Intereses</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(plan, n) in selectedPlan" :key="n">
						<td class="d-flex align-center">
							<Media width="45" v-if="plan.specialPlanLogo" :src="plan.specialPlanLogo" />
							<span v-if="plan.specialPlanName">{{ plan.specialPlanName }}</span>
						</td>
						<td>
							{{ plan.qty }}
						</td>
						<td>
							<PriceText :price-class="$vars.base" :amount="plan.installmentsAmount" />
						</td>
						<td><PriceText :price-class="$vars.base" :amount="plan.total" /></td>
						<td v-if="noSurcharge(plan)" class="px-0">
							<v-chip color="success" class="pa-2">Sin interés</v-chip>
						</td>
						<td v-else><PriceText :price-class="$vars.base" :amount="plan.surcharge" /></td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<style lang="scss" scoped>
tbody tr td {
	& tr:hover {
		background: inherit !important;
	}
	&.payments {
		position: sticky;
		left: 0;
		font-weight: bold;
		font-size: 1.2rem !important;
		color: var(--link) !important;
	}

	font-size: 1.1rem !important;
}

.payments-table {
	border-bottom: 1px solid #e0e0e0;
	thead tr th {
		font-weight: lighter;
	}
}

table > tbody > tr:hover {
	background: inherit !important;
}

tbody tr td {
	&.payments {
		position: sticky;
		left: 0;
		background-color: var(--base) !important;
		font-weight: bold;
		font-size: 1.2rem !important;
		color: var(--link) !important;
	}

	font-size: 1.1rem !important;
}
</style>

<script>
export default {
	props: {
		items: Array,
		value: {
			type: Object,
			required: true,
		},
		label: String,
	},
	computed: {
		modelValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
}
</script>

<template>
	<v-select
		v-bind="$attrs"
		:items="items"
		v-model="modelValue"
		return-object
		outlined
		:menu-props="{ bottom: true, offsetY: true }"
		hide-details
	>
		<template v-slot:selection="{ item }">
			<div class="pa-2">
				<div class="mb-4 font-weight-bold">{{ label }}</div>
				<div class="d-flex align-center">
					<Media v-if="item.image" :src="item.image" width="45" as="img" contain eager class="mr-3" />
					<div>
						<div class="font-1">{{ item[$attrs['item-text']] }}</div>
					</div>
				</div>
			</div>
		</template>
		<template v-slot:item="{ item }">
			<div class="d-flex align-center">
				<Media v-if="item.image" :src="item.image" width="45" as="img" contain eager class="mr-3" />
				<div class="font-1">{{ item[$attrs['item-text']] }}</div>
			</div>
		</template>
		<template v-slot:append>
			<v-icon large>mdi-chevron-down</v-icon>
		</template>
	</v-select>
</template>

<style scoped>
.v-select::v-deep .v-input__append-inner {
	margin-top: 50px;
}
.v-text-field--enclosed .v-input__append-inner {
	margin-top: 50px;
}

.v-text-field--outlined::v-deep {
	border-color: red;
}
</style>

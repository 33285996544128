<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	cssVars: {
		selector: '.product-card',
	},

	props: {
		checkedModel: Boolean,
	},

	model: {
		prop: 'checkedModel',
		event: 'updateModel',
	},
	data() {
		return {
			checked: false,
		}
	},

	computed: {
		checkBoxText() {
			return this.product.hasUniqueVariant ? 'Agregar al carrito' : 'Ver variantes'
		},
	},

	watch: {
		checked(value) {
			this.$emit('updateModel', value)
		},
	},
}
</script>

<template>
	<div class="checkbox-container d-flex flex-column h100 mb-3 product-card">
		<ProductCard :product="product" upsellCard vertical />
		<router-link
			class="d-flex justify-center pt-4 mb-2 secondary checkbox card-anchor"
			:to="productRoute"
			v-if="!product.hasUniqueVariant"
		>
			<span class="font-0 link--text">{{ checkBoxText | lang }}</span>
		</router-link>
		<div
			class="d-flex justify-center pt-4 mb-2 secondary checkbox checkbox--active"
			v-else
			@click="checked = !checked"
		>
			<v-checkbox v-model="checked" @click.stop="" class="mt-0 pt-0" />
			<span class="font-0 link--text">{{ checkBoxText | lang }}</span>
		</div>
	</div>
</template>

<style lang="scss" scoped>
// .checkbox-container {
// 	z-index: 1;
// }

.checkbox-container:hover {
	transform: scale(1.02);
	transition: transform 0.4s ease-in-out;
}

.checkbox {
	position: relative;
	top: -4px;
	width: 106%;
	left: 50%;
	transform: translateX(-50%);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	box-shadow: 0px -6px 3px -4px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px -6px 3px -4px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px -6px 3px -4px rgba(0, 0, 0, 0.2);
	min-height: 62px;
	z-index: 2;

	&--active {
		cursor: pointer;
	}
}
</style>
